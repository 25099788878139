<template>
  <div class="container page">
    <van-nav-bar :title="$t('收款卡信息')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <div class="add-card" @click="toBindCard()" v-if="!is_bind">
        <van-icon name="plus" />
        <span>{{ $t("添加收款卡") }}</span>
      </div>

      <hr v-if="!is_bind">
      <div class="add-card" @click="toBindZsk()" v-if="!is_bind">
        <van-icon name="plus" />
        <span>{{ $t("添加转数快") }}</span>
      </div>

      <!-- <hr v-if="!is_bind_zsk">
      <div v-if="!is_bind_zsk">
        <div class="add-card">
          &nbsp;&nbsp;&nbsp; <span>{{ $t("上传收款码") }}</span> &nbsp;&nbsp;&nbsp;
          <input type="file" class="inputs" ref="upFile" @change="file123()">
          
        </div>
      </div>
      
      <div v-if="is_bind_zsk">
        已绑定收款码
      </div> -->

      <!-- <div class="add-card" @click="toBindZsk()" v-if="!is_bind">
        <van-icon name="plus" />
        <span>{{ $t("添加收款码") }}</span>
      </div> -->

      <!-- <div class="bank" v-else>
        <div class="info">
          <div class="row-content">{{ this.bankInfo.bankinfo }}</div>
          <div class="row-content">{{ this.userInfo.name }}</div>
          <div class="row-content">{{ this.bankInfo.bankid }}</div>
          <div class="row-content">{{ this.bankInfo.phone_number }}</div>
        </div>
      </div> -->
      <div class="tips">{{ $t("提示:请设置大型商业银行,如需修改,请您联系在线客服") }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_bind: false,
      is_bind_zsk: false,
      is_bind_wchat_qrcode: false,
      is_bind_alipay_qrcode: false,
      bankInfo: {},
      userInfo: {},
      file: '',
      imgs: '',
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserBankInfo() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo.name = res.data.info.name;
          this.is_bind_alipay_qrcode = res.data.is_bind_alipay_qrcode;
          this.is_bind_wchat_qrcode = res.data.is_bind_wchat_qrcode;
          if (res.data.is_bank) {
            this.is_bind = true;
            this.bankInfo = res.data.info;
          } else {
            this.is_bind = false;
          }
          this.is_bind_zsk = res.data.is_bind_zsk || false;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    toBindWchatQrcodeClick() {
      this.$refs.toBindWchatQrcode.click();
    },
    toBindAlipayQrcodeClick() {
      this.$refs.toBindAlipayQrcode.click();
    },
    toBindWchatQrcode(event) {
      let file = event.target.files[0];
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 2MB
        if (file.size > maxSize) {
          // 符合要求的文件大小，执行上传逻辑
          this.$toast(this.$t("文件大小超过限制（最大5MB）"));
          return false;
        }
        const formData = new FormData();
        formData.append('file', file);
        this.$http({
          method: 'formData',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: 'point_img'
        }).then(res => {
          if (res.code === 200) {
            this.userInfo.name = res.data.info.name;
            if (res.data.is_bank) {
              this.is_bind = true;
              this.bankInfo = res.data.info;
            } else {
              this.is_bind = false;
            }
            this.is_bind_zsk = res.data.is_bind_zsk || false;
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        })

      }
    },
    toBindAlipayQrcode(event) {
      let file = event.target.files[0];
      if (file) {
        const maxSize = 5 * 1024 * 1024; // 2MB
        if (file.size > maxSize) {
          // 符合要求的文件大小，执行上传逻辑
          this.$toast(this.$t("文件大小超过限制（最大5MB）"));
          return false;
        }

      }
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    toBindZsk() {
      if (!this.userInfo.name) {
        this.$router.push("Setname");
        this.$toast(this.$t("请设置姓名后再绑定银行卡"));
        return true;
      }
      // else if(!this.userInfo.paypassword){
      //   this.$router.push("SetPayPassword");
      //   this.$toast(this.$t("请设置提现密码后再绑定银行卡"));
      //   return true;
      // }
      else {
        this.$router.push({ path: '/BindZsk' })
      }
    },
    toBindCard() {
      if (!this.userInfo.name) {
        this.$router.push("Setname");
        this.$toast(this.$t("请设置姓名后再绑定银行卡"));
        return true;
      }
      // else if(!this.userInfo.paypassword){
      //   this.$router.push("SetPayPassword");
      //   this.$toast(this.$t("请设置提现密码后再绑定银行卡"));
      //   return true;
      // }
      else {
        this.$router.push({ path: '/BindCard' })
      }
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}

.manage-card .wrapper {
  height: calc(100% - 10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.wrapper .add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  height: 250px;
}

.wrapper .add-card span {
  margin-left: 10px;
  font-size: 30px;
}

.wrapper .tips {
  margin: 15px 15px;
  font-size: 25px;
  color: #979799;
}

.wrapper .bank .info {
  margin-left: 20px;
  flex: 1;
  color: #000;
}

.wrapper .bank .info .row-content {
  margin: 30px 0;
  line-height: 20px;
  font-size: 30px;
}
</style>
